<template>
  <div>
    <v-card data-testid="container-debug-signal-servers">
      <v-toolbar>
        <v-card-title>Debug Info Signal servers</v-card-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text>
        <DataTableExtended
          ref="refTableSignals"
          :headers="headers"
          :items="debugSignalServersList"
          sortable
          class="elevation-0 border pt-3"
          :loading="showTableLoader"
          :no-data-text="showTableLoader ? 'Loading…' : 'No data available'"
          v-on:init-table-data="getSignalServersList"
          data-testid="table-debug-signal-servers"
        >
          <template v-slot:[`item.data.name`]="{ item }">
            <span data-testid="name">
              {{ item.data.name }}
            </span>
          </template>
          <template v-slot:[`item.data.url`]="{ item }">
            <span data-testid="url">
              {{ item.data.url }}
            </span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div v-if="!item.status" class="primary--text">
              <span class="overline">
                <v-progress-circular
                  :size="16"
                  indeterminate
                  color="primary"
                  class="mx-2"
                  data-testid="progress-loading"
                ></v-progress-circular>
              </span>
              <span class="font-weight-regular">Loading</span>
            </div>

            <div
              v-else
              :class="
                signalIsActive(item)
                  ? Pallette.statusText.active
                  : Pallette.statusText.notActive
              "
              data-testid="status"
            >
              <span class="overline" data-testid="status-icon">
                <i class="fa fa-circle mx-2"></i>
              </span>
              <span class="font-weight-regular" data-testid="status-text">{{
                signalIsActive(item) ? "Active" : "Inactive"
              }}</span>
            </div>
          </template>
          <template v-slot:[`item.config.withTokenAuthentication`]="{ item }">
            <div v-if="!item.configs" class="primary--text">
              <v-progress-circular
                :size="16"
                indeterminate
                color="primary"
                class="mx-2"
                data-testid="token-auth-progress-loading"
              ></v-progress-circular>
            </div>
            <div
              v-else-if="
                typeof item.configs.withTokenAuthentication === 'string'
              "
              data-testid="token-auth"
            >
              <span class="opaced">{{
                item.configs.withTokenAuthentication
              }}</span>
            </div>
            <div
              v-else
              :class="
                item.configs.withTokenAuthentication
                  ? 'light-green--text'
                  : 'red--text'
              "
              data-testid="token-auth"
            >
              <span class="font-weight-regular">{{
                item.configs.withTokenAuthentication ? "On" : "Off"
              }}</span>
            </div>
          </template>
          <template v-slot:[`item.config.streamLicenseCheck`]="{ item }">
            <div v-if="!item.configs" class="primary--text">
              <v-progress-circular
                :size="16"
                indeterminate
                color="primary"
                class="mx-2"
                data-testid="license-check-progress-loading"
              ></v-progress-circular>
            </div>
            <div
              v-else-if="!item.configs.hasOwnProperty('streamLicenseCheck')"
              data-testid="license-check-status"
            >
              <span class="opaced">Unknown</span>
            </div>
            <div
              v-else-if="typeof item.configs.streamLicenseCheck === 'string'"
              data-testid="license-check-status"
            >
              <span class="opaced">{{ item.configs.streamLicenseCheck }}</span>
            </div>
            <div
              v-else
              :class="
                item.configs.streamLicenseCheck
                  ? 'light-green--text'
                  : 'red--text'
              "
              data-testid="license-check-status"
            >
              <span class="font-weight-regular">{{
                item.configs.streamLicenseCheck ? "On" : "Off"
              }}</span>
            </div>
          </template>
          <template v-slot:[`item.config.backendConnected`]="{ item }">
            <div v-if="!item.configs" class="primary--text">
              <v-progress-circular
                :size="16"
                indeterminate
                color="primary"
                class="mx-2"
                data-testid="backend-connected-progress-loading"
              ></v-progress-circular>
            </div>
            <div
              v-else-if="typeof item.configs.backendConnected === 'string'"
              data-testid="backend-connected-status"
            >
              <span class="text--secondary">{{
                item.configs.backendConnected
              }}</span>
            </div>
            <div
              v-else
              :class="
                item.configs.backendConnected
                  ? 'light-green--text'
                  : 'red--text'
              "
              data-testid="backend-connected-status"
            >
              <span class="font-weight-regular">{{
                item.configs.backendConnected ? "On" : "Off"
              }}</span>
            </div>
          </template>
          <template v-slot:[`item.config.version`]="{ item }">
            <div v-if="!item.configs" class="primary--text">
              <v-progress-circular
                :size="16"
                indeterminate
                color="primary"
                class="mx-2"
                data-testid="version-progress-loading"
              ></v-progress-circular>
            </div>
            <div v-else data-testid="version">
              <span :class="{ opaced: item.configs.version === 'unknown' }">{{
                item.configs.version
              }}</span>
            </div>
          </template>
        </DataTableExtended>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import DataTableExtended from "@/components/table/DataTableExtended"
import {
  SignalServerService,
  STATUS_SERVER_ACTIVE,
  STATUS_SERVER_INACTIVE,
} from "@/common/signal.service"

export default {
  components: {
    DataTableExtended,
  },

  data() {
    return {
      headers: [
        {
          text: "Name",
          align: "left",
          value: "data.name",
          width: 200,
          sortable: true,
        },
        {
          text: "Status",
          align: "center",
          value: "status",
          width: 130,
          sortable: true,
        },
        {
          text: "Url",
          align: "left",
          value: "data.url",
          sortable: true,
        },
        {
          text: "Token Auth",
          align: "left",
          value: "config.withTokenAuthentication",
          sortable: false,
        },
        {
          text: "Backend connected",
          align: "left",
          value: "config.backendConnected",
          sortable: false,
        },
        {
          text: "License check",
          align: "left",
          value: "config.streamLicenseCheck",
          sortable: false,
        },
        {
          text: "Api version",
          align: "left",
          value: "config.version",
          sortable: false,
        },
      ],
    }
  },

  methods: {
    getSignalServersList: function () {
      let self = this
      let getParams = self.$refs.refTableSignals.getTableServerParams()
      let fetchData = {
        params: getParams,
      }
      self.$store.dispatch("debugSignalServersList", fetchData).then(() => {
        this.getSignalsAliveStatuses()
        this.getSignalsConfigs()
      })
    },
    getSignalsAliveStatuses() {
      let self = this
      self.debugSignalServersList.forEach((signal, index) => {
        SignalServerService.health(signal.data.url)
          .then((res) => {
            let status = res?.data?.data?.is_alive
              ? STATUS_SERVER_ACTIVE
              : STATUS_SERVER_INACTIVE
            self.$store.dispatch("setStatusSignal", {
              key: signal._key,
              status: status,
            })
          })
          .catch(({ response }) => {
            // console.error('Error--getSignalsAliveStatuses', this.signalServers[index].status, response)
            self.$store.dispatch("setStatusSignal", {
              key: signal._key,
              status: STATUS_SERVER_INACTIVE,
            })
          })
      })
    },
    getSignalsConfigs() {
      let self = this
      let configsDefault = {
        withTokenAuthentication: "unknown",
        backendConnected: "unknown",
        streamLicenseCheck: "unknown",
        version: "unknown",
      }
      self.debugSignalServersList.forEach((signal, index) => {
        SignalServerService.configs(signal.data.url)
          .then((res) => {
            self.$store.dispatch("setConfigsSignal", {
              key: signal._key,
              configs: res?.data?.data || configsDefault,
            })
          })
          .catch(({ response }) => {
            // console.error('Error--getSignalsConfigs', response)
            self.$store.dispatch("setConfigsSignal", {
              key: signal._key,
              configs: configsDefault,
            })
          })
      })
    },

    signalIsActive(item) {
      return item.status === STATUS_SERVER_ACTIVE
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      Pallette: "get_Pallette",
      debugSignalServersList: "debugSignalServersList",
      debugSignalServersListCount: "debugSignalServersListCount",
    }),
  },
}
</script>

<style lang="scss" scoped>
.opaced {
  opacity: 0.6;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"data-testid":"container-debug-signal-servers"}},[_c('v-toolbar',[_c('v-card-title',[_vm._v("Debug Info Signal servers")]),_c('v-spacer')],1),_c('v-card-text',[_c('DataTableExtended',{ref:"refTableSignals",staticClass:"elevation-0 border pt-3",attrs:{"headers":_vm.headers,"items":_vm.debugSignalServersList,"sortable":"","loading":_vm.showTableLoader,"no-data-text":_vm.showTableLoader ? 'Loading…' : 'No data available',"data-testid":"table-debug-signal-servers"},on:{"init-table-data":_vm.getSignalServersList},scopedSlots:_vm._u([{key:`item.data.name`,fn:function({ item }){return [_c('span',{attrs:{"data-testid":"name"}},[_vm._v(" "+_vm._s(item.data.name)+" ")])]}},{key:`item.data.url`,fn:function({ item }){return [_c('span',{attrs:{"data-testid":"url"}},[_vm._v(" "+_vm._s(item.data.url)+" ")])]}},{key:`item.status`,fn:function({ item }){return [(!item.status)?_c('div',{staticClass:"primary--text"},[_c('span',{staticClass:"overline"},[_c('v-progress-circular',{staticClass:"mx-2",attrs:{"size":16,"indeterminate":"","color":"primary","data-testid":"progress-loading"}})],1),_c('span',{staticClass:"font-weight-regular"},[_vm._v("Loading")])]):_c('div',{class:_vm.signalIsActive(item)
                ? _vm.Pallette.statusText.active
                : _vm.Pallette.statusText.notActive,attrs:{"data-testid":"status"}},[_c('span',{staticClass:"overline",attrs:{"data-testid":"status-icon"}},[_c('i',{staticClass:"fa fa-circle mx-2"})]),_c('span',{staticClass:"font-weight-regular",attrs:{"data-testid":"status-text"}},[_vm._v(_vm._s(_vm.signalIsActive(item) ? "Active" : "Inactive"))])])]}},{key:`item.config.withTokenAuthentication`,fn:function({ item }){return [(!item.configs)?_c('div',{staticClass:"primary--text"},[_c('v-progress-circular',{staticClass:"mx-2",attrs:{"size":16,"indeterminate":"","color":"primary","data-testid":"token-auth-progress-loading"}})],1):(
              typeof item.configs.withTokenAuthentication === 'string'
            )?_c('div',{attrs:{"data-testid":"token-auth"}},[_c('span',{staticClass:"opaced"},[_vm._v(_vm._s(item.configs.withTokenAuthentication))])]):_c('div',{class:item.configs.withTokenAuthentication
                ? 'light-green--text'
                : 'red--text',attrs:{"data-testid":"token-auth"}},[_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(item.configs.withTokenAuthentication ? "On" : "Off"))])])]}},{key:`item.config.streamLicenseCheck`,fn:function({ item }){return [(!item.configs)?_c('div',{staticClass:"primary--text"},[_c('v-progress-circular',{staticClass:"mx-2",attrs:{"size":16,"indeterminate":"","color":"primary","data-testid":"license-check-progress-loading"}})],1):(!item.configs.hasOwnProperty('streamLicenseCheck'))?_c('div',{attrs:{"data-testid":"license-check-status"}},[_c('span',{staticClass:"opaced"},[_vm._v("Unknown")])]):(typeof item.configs.streamLicenseCheck === 'string')?_c('div',{attrs:{"data-testid":"license-check-status"}},[_c('span',{staticClass:"opaced"},[_vm._v(_vm._s(item.configs.streamLicenseCheck))])]):_c('div',{class:item.configs.streamLicenseCheck
                ? 'light-green--text'
                : 'red--text',attrs:{"data-testid":"license-check-status"}},[_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(item.configs.streamLicenseCheck ? "On" : "Off"))])])]}},{key:`item.config.backendConnected`,fn:function({ item }){return [(!item.configs)?_c('div',{staticClass:"primary--text"},[_c('v-progress-circular',{staticClass:"mx-2",attrs:{"size":16,"indeterminate":"","color":"primary","data-testid":"backend-connected-progress-loading"}})],1):(typeof item.configs.backendConnected === 'string')?_c('div',{attrs:{"data-testid":"backend-connected-status"}},[_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(item.configs.backendConnected))])]):_c('div',{class:item.configs.backendConnected
                ? 'light-green--text'
                : 'red--text',attrs:{"data-testid":"backend-connected-status"}},[_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(item.configs.backendConnected ? "On" : "Off"))])])]}},{key:`item.config.version`,fn:function({ item }){return [(!item.configs)?_c('div',{staticClass:"primary--text"},[_c('v-progress-circular',{staticClass:"mx-2",attrs:{"size":16,"indeterminate":"","color":"primary","data-testid":"version-progress-loading"}})],1):_c('div',{attrs:{"data-testid":"version"}},[_c('span',{class:{ opaced: item.configs.version === 'unknown' }},[_vm._v(_vm._s(item.configs.version))])])]}}],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }